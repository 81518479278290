import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecurityPage = () => (
  <Layout>
    <SEO title="Security" keywords={[`nvotes`, `secure`, `online`, `voting`, `tools`, `software`, `system`, `election`, `poll`]} />
    <article className="slim">
      <h1>Security</h1>
      <div>
      <p>Most online voting systems claim to be secure referring to cybersecurity and resistance to cyberattacks. However, this is not enough. A voting system is secure in the sense that we can trust that the results of an election are fair and correct while protecting voters&rsquo; privacy. This is challenging because a voter must be able to trace the effect of her vote on the result, while on the other hand privacy requires that a vote cannot be traced back from the result to a voter.</p>
      <h2>End-to-end verifiability</h2>
      <p>Not only must the system operate correctly and election results must be fair, but it must be possible for participants and external observers to certify this unequivocally. nVotes auditable voting platform makes it possible for voters to ensure that:</p>
      <ul>
      <li>Their choice was correctly encoded on the ballot by the system (cast-as-intended)</li>
      <li>Their ballot was received the way they cast it (recorded-as-cast)</li>
      <li>Their ballot counts as received (tallied-as-recorded)</li>
      </ul>
      <h2>Privacy</h2>
      <p>nVotes keeps ballots truly secret so that a vote does not identify a voter and any traceability between the voter and its vote is removed. Not even the administrators of the voting system or anyone with privileged access to hardware/software can violate this privacy.</p>
      </div>
      <h2>What do we mean by&nbsp;<em>secure voting</em>?</h2>
      <p>The expression has different meanings depending on context. On one hand, we can speak about secure voting in the general context of&nbsp;<a href="https://en.wikipedia.org/wiki/Computer_security">cybersecurity</a>. On the other, secure voting has more specific definitions in the academic&nbsp;<a href="https://en.wikipedia.org/wiki/End-to-end_auditable_voting_systems">research</a>&nbsp;literature into voting systems. In this post we try to clarify the meaning of secure voting, starting from general intuitions leading to more precise technical definitions.</p>
      <p>In a general context, secure voting is understood to be about methods, software and systems that aim to protect an election from fraud and disruption. It a question of correctness and integrity. A voting system is secure in the sense that we can trust that the results of an election are fair and correct. The main threats faced by a secure voting system are those typical of any computer security problem: hacking, intrusion, manipulation, disruption. If we restricted our discussion to this general context, secure voting would be &ldquo;just&rdquo; another problem in cybersecurity.</p>
      <p>However, election integrity is not the whole story. Although the concept of cybersecurity includes concerns about data theft and privacy, the emphasis on privacy in the case of voting is critical. It&rsquo;s what separates secure voting from more general problems in cybersecurity. This emphasis stems from voting&rsquo;s nature as a political activity, where the crucial importance of the secret ballot is well established. The importance of the secret ballot has been recognized since roman times, and is even enshrined in the declaration of human rights<sup>[4]</sup>.</p>
      <blockquote>
      <p>Article 21.3 of the&nbsp;<em><a title="Universal Declaration of Human Rights" href="https://en.wikipedia.org/wiki/Universal_Declaration_of_Human_Rights">Universal Declaration of Human Rights</a></em>&nbsp;states, &ldquo;The will of the people&hellip;shall be expressed in periodic and genuine elections which&hellip;shall be held by secret vote or by equivalent free voting procedures.&rdquo;</p>
      </blockquote>
      <p>Accounting for this, the goal of secure voting is then protecting voter&nbsp;<em>privacy</em>&nbsp;as well as election integrity. It turns out that these two objectives are fundamentally opposed. For this reason, secure voting as a technical discipline is about finding methods that allow achieving both objectives simultaneously. It is in this domain (the academic literature on secure voting) where we find more precise definitions that factor in the core tension that general considerations about cybersecurity fail to address. And it is in this technical domain where voting-specific cryptography is employed to solve the unique problems that arise. Thus, the term &ldquo;cryptographically secure voting&rdquo; seems a reasonable choice to refer to this refined, more specific meaning.</p>
      <p>At a high level<sup>[3]</sup>, the goals of cryptographically secure voting are described by<sup>[1]</sup></p>
      <blockquote>
      <p>One of the most challenging aspects in computer-supported voting is to combine the apparently conflicting requirements of privacy and verifiability. On the one hand, privacy requires that a vote cannot be traced back from the result to a voter, while on the other hand, verifiability states that a voter can trace the effect of her vote on the result. This can be addressed using various privacy-enabling cryptographic primitives which also offer verifiability.</p>
      </blockquote>
      <p>We stress that the use of cryptography is a means to an end, not the end in itself. A voting system that includes cryptographic techniques is not necessarily a cryptographically secure voting system. This mistake is commonly made when assessing blockchain systems: these systems use cryptography, but this cryptography has no bearing on privacy<sup>[5]</sup>. Cryptography is there to satisfy certain requirements, not the other way around.</p>
      <p>Let&rsquo;s now pin down exactly what these requirements entail.</p>
      <h2>Privacy</h2>
      <p>Starting with privacy<sup>[2]</sup></p>
      <blockquote>
      <p>Privacy: In a secret ballot, a vote must not identify a voter and any traceability between the voter and its vote must be removed.</p>
      </blockquote>
      <p>An alternative statement is<sup>[1]</sup></p>
      <blockquote>
      <p>Ballot-privacy: no outside observer can determine for whom a voter voted</p>
      </blockquote>
      <p>Note that the expression &ldquo;no outside observer&rdquo; refers to anybody that is not the voter. The important implication is that not even the administrators of the voting system or anyone with privileged access to hardware/software can violate this privacy. If this condition is not met, a system does not support privacy and is therefore not a secure voting system. Solutions that simply &ldquo;forget&rdquo; data, or merely store data at different locations, do not satisfy privacy. It is not enough for the system to voluntarily disregard privacy-compromising information; said information must not be available at all. Relaxing this privacy requirement makes a building voting system trivial, typically reducing to the use of SSL for communication.</p>
      <h2>Verifiability</h2>
      <p>The literature presents several variants<sup>[1]</sup>:</p>
      <blockquote>
      <p>Individual verifiability (IV): a voter can verify that the ballot containing her vote is in the published set of &ldquo;all&rdquo; (as claimed by the system) votes.</p>
      <p>Universal verifiability (UV): anyone can verify that the result corresponds with the published set of &ldquo;all&rdquo; votes.</p>
      </blockquote>
      <p>These two requirements appeared first in the literature, and were later augmented with[1]</p>
      <blockquote>
      <p>End-to-end verifiability: a voter can verify that:<br />&ndash; cast-as-intended: her choice was correctly denoted on the ballot by the<br />system,<br />&ndash; recorded-as-cast: her ballot was received the way she cast it,<br />&ndash; tallied-as-recorded: her ballot counts as received.</p>
      </blockquote>
      <p>The notion of verifiability of a voting system is directly related to integrity, and is in fact a strictly stronger property. Not only must the system operate correctly and election results must be fair, but it must be possible for participants and external observers to certify this unequivocally. Verifiability is one of the areas in which electronic voting systems may offer better guarantees than traditional voting. This is accomplished through cryptographic proofs and publicly available bulletin boards that collect election data.</p>
      <h2>End-to-end verifiable voting systems</h2>
      <p>With these defintions in hand we can suggest:</p>
      <p><em>A cryptographically secure voting system is one that supports privacy and end-to-end verifiability.</em></p>
      <p>For brevity, these systems are referred to simply as end-to-end verifiable. End-to-end verifiable systems are considered the goal standard for electronic voting. When these characteristics are further combined with general computer security techniques the result is a generally secure voting system.</p>
      <h2>Summary</h2>
      <p>We have seen that</p>
      <ul>
      <li>The term &ldquo;secure voting&rdquo; is generally thought to refer to cybersecurity and resistance to cyberattacks.</li>
      <li>However, cybersecurity is a general property of hardware/software that does not reflect the specific requirements of voting as a political process. The secret ballot is an established and indispensable requirement for voting.</li>
      <li>Secure voting systems must support privacy as well as integrity; these two requirements stand in opposition.</li>
      <li>In a system supporting privacy, no one, not even system administrators or other privileged observers can violate the secret ballot.</li>
      <li>In a system supporting end-to-end verifiability, voters can ensure that their vote was cast, recorded, and counted correctly.</li>
      <li>Cryptographically secure voting systems employ cryptographic technology to satisfy these two properties simultaneously. The gold standard are end-to-end verifiable voting systems.</li>
      <li><strong>A secure voting system is an end-to-end verifiable voting system that also employs general computer security principles.</strong></li>
      </ul>
      <p>This last point expresses our view of what it means for a voting system to be secure. Although this definition is very demanding, we believe it is appropriate to be conservative in an area that overlaps with political decision making. Unfortunately this approach implies that many systems that are labelled secure voting systems do not in fact belong to that category.</p>
      <hr />
      <h2>References</h2>
      <p>[1] Privacy and Verifiability in Voting Systems: Methods, Developments and Trends [<a href="https://eprint.iacr.org/2013/615.pdf" target="_blank" rel="noopener noreferrer">https://eprint.iacr.org/2013/615.pdf</a>]</p>
      <p>[2] A framework and taxonomy for comparison of electronic voting schemes [<a href="https://www2.ee.washington.edu/research/nsl/papers/JCS-05.pdf" target="_blank" rel="noopener noreferrer">https://www2.ee.washington.edu/research/nsl/papers/JCS-05.pdf</a>]</p>
      <p>[3] The literature includes many other properties relevant to secure voting, we concentrate on the essential ones. Please refer to [2] for further info.</p>
      <p>[4] Secret ballot [<a href="https://en.wikipedia.org/wiki/Secret_ballot#International_law" target="_blank" rel="noopener noreferrer">https://en.wikipedia.org/wiki/Secret_ballot#International_law</a>]</p>
      <p>[5] Unless you include specific cryptographic techniques such as zk-snarks (zcash) or linkable ring signatures in addition to the blockchain. One of our proposals is related to the first case, found&nbsp;<a href="https://bitcoinmagazine.com/articles/agora-voting-proposes-bitcoin-based-voting-system-1390288011/">here</a>. Another proposal related to blockchain&rsquo;s distributed nature is&nbsp;<a href="https://nvotes.com/elements-of-distributed-voting-system/">here</a>.</p>
    </article>
  </Layout>
)

export default SecurityPage
